@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#parent-area {
  position: relative;
  min-height: 100vh;
  background-color: #f5f5f5;
  display: flex;
}

#content-wrap {
  padding-bottom: 70px; /* Footer height */
  display: flex;
  align-items: stretch;
  width: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px; /* Footer height */
}

.language_box {
  background: url("https://cdn.casbin.org/img/muti_language.svg");
  background-size: 25px, 25px;
  background-position: center;
  background-repeat: no-repeat;
  width: 45px;
  height: 65px;
  float: right;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.rightDropDown {
  &:hover {
    background-color: #f5f5f5;
  }
}

.content-warp-card {
  box-shadow: 0 1px 5px 0 rgba(51, 51, 51, 0.14);
  margin: 5px 5px 5px 5px;
  flex: 1;
  align-items: stretch;
}

@primary-color: rgb(45,120,213);